import React, { useState } from "react";
import "./SeeHow.scss";
import { Modal } from "antd";
import seehow1 from "../../Assets/seehow1.webp";
import seehow2 from "../../Assets/seehow2.webp";
import seehow3 from "../../Assets/seehow3.webp";
import seehow4 from "../../Assets/seehow4.webp";

const SeeHow = ({ data, search }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const steps = [
    {
      id: 1,
      image: seehow1,
      description: "Book a new dentist with Dentistfinder",
    },
    {
      id: 2,
      image: seehow2,
      description: "Visit your new Dentistfinder dentist",
    },
    {
      id: 3,
      image: seehow3,
      description: "Submit your receipt to Dentistfinder.ai",
    },
    {
      id: 4,
      image: seehow4,
      description: data?.offer_description,
    },
  ];
  return (
    <>
      <div className="seeHow">
        <div className="seehow-text">
          <span className="offer"> {data?.offer_description}</span>
        </div>
        <div>
          <button className="see-btn" onClick={showModal}>
            See how
          </button>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={900}
        footer={null}
        modalRender={(modal) => (
          <div className="custom-modal-wrapper">{modal}</div>
        )}
      >
        <div className="custom-modal">
          <div className="custom-modal-header">
            <h2>Smile More. Get More.</h2>
          </div>

          <div className="custom-modal-body">
            <div className="row g-md-0 g-5">
              <div className="col-4">
                <div className="steps">
                  {steps.map((step, index) => (
                    <div className="step-item" key={step.id}>
                      <img src={step.image} alt={`Step ${step.id}`} />
                      {/* <div className="step-number">
                    <span>{index + 1}</span>
                  </div>
                  {index !== steps.length - 1 && <div className="line"></div>} */}
                      <div className="step-number">
                        <span>{index + 1}</span>
                        {index !== steps.length - 1 && (
                          <div className="line"></div>
                        )}
                      </div>
                      <p>{step.description}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-8">
                <div className="eligibility">
                  <h3>Am I eligible?</h3>
                  <ul>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      <span className="text">
                        You complete a new patient exam and another dental
                        service, such as a cleaning. Dental consultations,
                        including x-rays, do not qualify for rewards.
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      <span className="text">
                        You are at least 18 years old on the date of your
                        appointment.
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      <span className="text">
                        You have not claimed an Dentistfinder reward in the past
                        2 years.
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      <span className="text">
                        You are not an existing patient of the dental practice.
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      <span className="text">
                        You are not using government funded insurance (e.g.,
                        Medicaid, Medicare, provincial health plans in Canada,
                        or the Canada Dental Care Plan)
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      <span className="text">
                        Your appointment was booked using the name on your
                        DentistFinder account. If one account was used to book
                        appointments or two or more patients, only one patient
                        will be able to claim a reward.
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="find-dentist d-md-block d-none">
                  <button
                    style={{ backgroundColor: "#FF8FB0" }}
                    onClick={() => {
                      setIsModalOpen(false);
                      search();
                    }}
                  >
                    Find a Dentist
                  </button>
                  <p className="fint-text">
                    For more information, visit www.dentistfinder.ai or contact
                    support@dentistfinder.ai
                  </p>
                </div>
              </div>
            </div>
            <div className="find-dentist d-md-none d-block">
                  <button
                    style={{ backgroundColor: "#FF8FB0" }}
                    onClick={() => {
                      setIsModalOpen(false);
                      search();
                    }}
                  >
                    Find a Dentist
                  </button>
                  <p className="fint-text">
                    For more information, visit www.dentistfinder.ai or contact
                    support@dentistfinder.ai
                  </p>
                </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SeeHow;
