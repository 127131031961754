import "./App.css";
import AllRoute from "./AllRoutes/AllRoute";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ChatSupport from "./components/ChatSupport/ChatSupport";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import LoadingBar from 'react-top-loading-bar';
import Loader from "./components/Loader/Loader";
import { useLoadScript } from "@react-google-maps/api";
const libraries = ["places"];

function App() {

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  const loading = useSelector((state) => state.loader.loading);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });


  
  return (
    <div className="App">
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}
      >
      <LoadingBar color="#1E90FF"
       progress={loading ? 100 : 0}
      
        />
        {/* <Loader loading={loading}/> */}
        <ToastContainer />
        <AllRoute />
        {/* {
          isAuthenticated && 
        <ChatSupport/>
           
        } */}
      </GoogleOAuthProvider>

    </div>
  );
}

export default App
